import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import './thank-you.scss';

const ThankYouPage = (props) => {
  const {
    location
  } = props;
  return (
    <Layout location={location}>
      <SEO title="Thank You | Bellfort" />
      <section className="thank-you-page">
        <div className="wrap">
          <div className="thank-you-message">
            <h1>Thank you for filling out your information</h1>
            <p>We will get in touch with you shortly.</p>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default ThankYouPage
